.richtext_anchor a {
  color: rgb(var(--tertiary));
}

.richtext_bullet_points ul, .richtext_ordered_lists ol {
  padding-top: 8px;
}

.richtext_bullet_points ul > li {
  position: relative;
  list-style-type: disc;
  margin-left: 1.25em;
}

.richtext_bullet_points ul > li::before, .richtext_ordered_lists ol > li::before {
  content: none !important;
  position: absolute;
  background-color: #000;
  border-radius: 50%;
}

.richtext_bullet_points ul > li *:last-child, .richtext_ordered_lists ol > li *:last-child {
  margin-top: 0;
  margin-bottom: 0;
}

.richtext_bullet_points ul > li *:first-child, .richtext_ordered_lists ol > li *:first-child {
  margin-top: 0;
  margin-bottom: 0;
}

.richtext_ordered_lists ol > li {
  position: relative;
  list-style-type: decimal;
  margin-left: 1.25em;
}

.richtext_blockquote blockquote {
  margin-top: 8px;
  border-left: 4px solid rgb(var(--tertiary));
  padding-left: 0.875em;
}

.richtext_ruler hr {
  margin: 1em;
}

.richtext_header h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin-bottom: 0.75rem;
}

.richtext_header h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 0.75rem;
}

.richtext_header h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.75rem;
}

.richtext_header h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.75rem;
}

.richtext_header h5 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 0.75rem;
}

.richtext_header h6 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
}
