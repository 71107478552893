.subscription_lightbox {
  position: fixed;
  z-index: 60;
  height: 100%;
}

.layer{
  position: absolute;
  height: 100%;
}

.content_area{
  position: absolute;
  width: 550px;
  height: 400px;
  border: solid 1px #002630;
  border-radius: 5px;
}

.backdrop{
  background-color: rgba(0,59,74,0.5);
  z-index: 1;
}

.heading_container{
  height: 160px;
  background-color: #003b4a;
}

.heading_container h1{
  color: white;
  margin-top: 10px;
  text-align: center;
  font-size: 35px;
}

.description_area{
  background-color: #5FD0DF;
  font-size: 12px;
  padding: 25px 30px 10px 30px;
}

.desktopView{display: block;}
.mobileView{display: none; font-size: 1.8vw;}

.field_container{
  display: flex;
  flex-direction: column;
  margin-top: -105px;
}

.inputField{
  margin-bottom: 12px;
  line-height: 25px;
  font-size: 14px;
  padding: 10px;
  border-radius: 10px;
}

.inputField::-webkit-outer-spin-button, .inputField::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

.inputField[type=number] {
  -moz-appearance: textfield;
}

.inputField::placeholder{
  color:#5FD0DF;
}

.inputField:-ms-input-placeholder{
  color:#5FD0DF;
}

.inputField::-ms-input-placeholder{
  color:#5FD0DF;
}

.inputField:focus{
  outline: none;
}

.close_button{
  background-color: #FFDD00;
  color: #003B4A;
  border: solid 1px #003B4A;
  border-radius: 60px;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  position: absolute;
  right: -15px;
  top: -15px;
  font-size: 22px;
  cursor: pointer;
}

.submit_container{
  background-color: #003B4A;
  padding: 0 30px;
  flex-grow: 1;
}

.submit_button{
  background-color: #FFDD00;
  color: #003B4A;
  font-weight: bold;
  display: inline;
  padding: 10px 50px;
  border-radius: 15px;
  cursor: pointer;
}

.addMarginTop{
  margin-top: 35px;
}

@media screen and (max-width: 650px) {
  .subscription_lightbox{
    height: unset;
    bottom: 0px;
  }

  .backdrop{
    display: none !important;
  }
  
  .desktopView{display: none;}
  .mobileView{display: block;}

  .submit_container{
    height: 100px;
    padding: 0 30px;
  }

  .layer{
    position: unset;
    height: unset;
  }
  .content_area{
    position: unset;
    height: unset;
    width: 100%;
  }

  .heading_container{
    height: 90px;
  }

  .close_button{
    right: 30px;
  }

  .field_container{
    margin-top: -50px;
  }
}
